module.exports = [{
      plugin: require('/builds/web/aprogsys-website-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/builds/web/aprogsys-website-gatsby/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://www.aprogsys.com/matomo","siteUrl":"https://www.aprogsys.com","disableCookies":true,"requireConsent":false},
    },{
      plugin: require('/builds/web/aprogsys-website-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/web/aprogsys-website-gatsby/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/web/aprogsys-website-gatsby/src/cms/cms.js","enableIdentityWidget":false},
    },{
      plugin: require('/builds/web/aprogsys-website-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
